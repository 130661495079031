import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import ActivitiesList from './ActivitiesList'

import {makeStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import TopBar from "./TopBar";



const useStyles = makeStyles({
    title: {
        'padding-top': '30px'
    }
});

function Home({user}) {
    const {firstName, lastName, jobTitle} = user;

    const classes = useStyles();
    const darkTheme = createTheme({
        palette: {
            type: 'dark',
        }
    });

    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline/>
            <TopBar/>
            <Container maxWidth="md">
                <Typography variant="h4" gutterBottom className={classes.title}>
                    Planning des activités
                </Typography>
                <Paper>
                    <ActivitiesList/>
                </Paper>
            </Container>
        </ThemeProvider>
    );
}

export default Home;
