import React, {useState} from 'react';
import TopBar from "./TopBar";
import {CssBaseline} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles({
    paper: {
        'padding-bottom': '30px',
        'padding-left': '35px',
        'padding-right': '35px',
        'padding-top': '20px',
    },
    button: {
        'margin-top': '30px'
    }
});

const SignInTwo = ({authenticityToken, matricule}) => {
    const classes = useStyles();

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = event => {
        event.preventDefault();
    };

    return (
        <>
            <CssBaseline/>
            <TopBar hideLogo/>
            <Container maxWidth="sm">
                <Typography variant="h5" component="h1">
                    SECURE LOGIN
                </Typography>
                <Paper className={classes.paper}>
                    <Typography variant="h6" component="h2">
                        CONNEXION AVEC VOTRE TOKEN
                    </Typography>
                    <p>Bonjour {matricule}</p>
                    <p>
                        &gt; <a href="/change_user">Changer d'utilisateur</a>
                    </p>
                    <form noValidate method="POST" action="/sign_in_2" accept-charset="UTF-8">
                        <input type="hidden" value={authenticityToken} name="authenticity_token"/>
                        <input type="hidden" value={matricule} name="user[email_matricule]"/>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="token">Entrez votre Token [code PIN + code token]</InputLabel>
                            <Input
                                id="token"
                                type={showPassword ? 'text' : 'password'}
                                name="user[token]"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <Grid container justify="flex-end" className={classes.button}>
                            <Grid item sm={6}>
                                <FormControl fullWidth>
                                    <Button type="submit" variant="contained" color="primary">
                                        Suivant
                                    </Button>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Container>
        </>
    )
};

export default SignInTwo;
