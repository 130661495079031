const dispatchBaseUrl = "https://www.simbrief.com/system/dispatch.php";

const createDispatchUrl = (flight) => {
    // https://www.simbrief.com/system/dispatch.php?airline=AFR&fltnum=1400&route=AGOPA+ARKIP&orig=LFPG&dest=LEMD&callsign=AFR89PG&civalue=10&date=09AUG22&deph=18&depm=55&steh=2&stem=15&reg=F-GTAJ
    const { dispatchParameters  } = flight;
    const params = new URLSearchParams(dispatchParameters);
    return `${dispatchBaseUrl}?${params}`;
}

export default createDispatchUrl;
