import React, {useState} from "react";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import FlightRoundedIcon from '@material-ui/icons/FlightRounded';
import FlightTakeoffRoundedIcon from '@material-ui/icons/FlightTakeoffRounded';
import FlightLandRoundedIcon from '@material-ui/icons/FlightLandRounded';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import { DateTime } from "luxon";
import {makeStyles} from '@material-ui/core/styles';
import Divider from "@material-ui/core/Divider";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import {ZoomInRounded} from "@material-ui/icons";

function dayTypeTranslation(dayType) {
    switch (dayType) {
        case 'off':
            return 'Congé';
        case 'rest':
            return 'Repos';
        case 'forced':
            return 'Forcé';
        default:
            return '';
    }
}

const useStyles = makeStyles({
    flightIcon: {
        transform: 'rotate(45deg)'
    }
});

export default function ActivityItem({day, activities}) {
    const n = activities.length;
    const classes = useStyles();

    const [open, setOpen] = useState(true);
    const handleClick = () => {
        setOpen(!open);
    };

    const formattedDay = DateTime.fromISO(day).setLocale('fr').toFormat('cccc dd LLLL yyyy');
    const formatDateTime = (dateTime, format) => {
        return DateTime.fromISO(dateTime).toLocaleString(DateTime.TIME_SIMPLE)
    };

    return (
        <>
            {
                activities[0].dayType ? (
                    <>
                        <ListItem>
                            <ListItemText
                                primary={formattedDay}
                                secondary={dayTypeTranslation(activities[0].dayType)}
                            />
                        </ListItem>
                        <Divider/>
                    </>
                ) : (
                    <>
                        <ListItem button onClick={handleClick}>
                            <ListItemText
                                primary={formattedDay}
                                secondary={`${n} ${n < 2 ? 'vol' : 'vols'}`}
                            />
                            {open ? <ExpandLess/> : <ExpandMore/>}
                        </ListItem>
                        <Divider/>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {
                                    activities.map(({
                                                        id,
                                                        flightNumber,
                                                        departureDatetime,
                                                        arrivalDatetime,
                                                        departureIata,
                                                        departureName,
                                                        arrivalIata,
                                                        arrivalName,
                                                        aircraftIcao,
														publishedDepartureDatetime,
														expectedArrivalDatetime,
                                                        reg,
                                                        uniqId,
                                                    }) => (
                                        <React.Fragment key={`flight-${id}`}>
                                            <ListItem>
                                                <ListItemIcon><FlightRoundedIcon
                                                    className={classes.flightIcon}/></ListItemIcon>
                                                <ListItemText primary={flightNumber}/>
                                                <ListItemIcon><FlightTakeoffRoundedIcon/></ListItemIcon>
                                                <Tooltip title={departureName} placement="left-start">
                                                    <ListItemText primary={`${departureIata}`}
                                                                  secondary={formatDateTime(publishedDepartureDatetime)}/>
                                                </Tooltip>
                                                <ListItemIcon><FlightLandRoundedIcon/></ListItemIcon>
                                                <Tooltip title={arrivalName} placement="left-start">
                                                    <ListItemText primary={`${arrivalIata}`}
                                                                  secondary={formatDateTime(expectedArrivalDatetime)}/>
                                                </Tooltip>
                                                <ListItemText primary={aircraftIcao}
                                                              secondary={reg}/>
                                                <ListItemSecondaryAction>
                                                    <a href={`/flights/${uniqId}`}>
                                                        <IconButton edge="end" aria-label="details">
                                                            <ZoomInRounded/>
                                                        </IconButton>
                                                    </a>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                            <Divider/>
                                        </React.Fragment>
                                    ))
                                }
                            </List>
                        </Collapse>
                    </>
                )
            }
        </>
    )
}
