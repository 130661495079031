import React from 'react';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    link: {
        'text-decoration': 'none',
        color: 'inherit'
    },
});

function Link(props) {
    const classes = useStyles();

    return <a rel="noreferrer" className={classes.link} {...props}>{props.children}</a>;
}

export default Link;
