class FlightsSource {
    constructor(consumer, flightID) {
        this._consumer = consumer;
        this._flightID = flightID;
        this._flight = null;
        this._channel = null;
        this._connecting = false;
    }

    connect() {
        if (!this._consumer) {
            return;
        }

        this._connecting = true;
        return new Promise(resolve => {
                this._channel = this._consumer.subscriptions.create(
                    {
                        channel: "FlightsChannel",
                        flight_id: this._flightID
                    }, {
                        received: (data) => {
                            this._flight = data;
                            if (this._onChange) {
                                this._onChange(this._flight);
                            }
                        },
                        connected: () => {
                            this._connecting = false;
                            resolve();
                        }
                    }
                );
            }
        )
    }

    disconnect() {
        if (this._connecting) {
            return;
        }

        this._channel?.unsubscribe();
        this._channel = null;
    }

    onChange(callback) {
        this._onChange = callback;
    }

    refresh() {
        this._channel?.send({ command: 'refresh' });
    }
}

export default FlightsSource;
