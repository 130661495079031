import {Modal} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, {useCallback, useContext, useEffect, useRef, useState} from "react";

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { DispatchContext } from "./FullMap";

const AddBookmarkModal = ({ open, onClose, bookmarks }) => {
    const dispatch = useContext(DispatchContext);

    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        const data = {};
        for (let [key, value] of formData.entries()) {
            data[key] = value;
        }
        if (bookmarks.find(bookmark => bookmark.name.toLowerCase() === data.name.toLowerCase())) {
            alert('Bookmark with this name already exists');
            return;
        }
        dispatch({ type: 'add_bookmark', bookmark: data.name });
        onClose();
    }, [dispatch, onClose]);

    return <Dialog open={open} onClose={onClose}>
        <form onSubmit={handleSubmit}>
            <DialogTitle>Add bookmark</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please add a name to your bookmark.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    name="name"
                    label="Bookmark name"
                    type="text"
                    fullWidthx
                    autoComplete="off"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button type="submit">Add</Button>
            </DialogActions>
        </form>
    </Dialog>
};

export default AddBookmarkModal;
