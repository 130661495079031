class BookmarksSource {
    constructor(consumer) {
        this._consumer = consumer;
        this._bookmarks = [];
        this._channel = null;
        this._connecting = false;
    }

    connect() {
        if (!this._consumer) {
            return;
        }

        this._connecting = true;
        return new Promise(resolve => {
                this._channel = this._consumer.subscriptions.create({channel: "BookmarksChannel"}, {
                    received: (data) => {
                        this._bookmarks = data;
                        if (this._onChange) {
                            this._onChange(this._bookmarks);
                        }
                    },
                    connected: () => {
                        this._connecting = false;
                        resolve();
                    }
                });
            }
        )
    }

    disconnect() {
        if (this._connecting) {
            return;
        }

        this._channel?.unsubscribe();
        this._channel = null;
    }

    onChange(callback) {
        this._onChange = callback;
    }

    async getBookmarks() {
        if (this._connecting) {
            return [];
        }

        return this._bookmarks;
    }

    async create(bookmarkName, location) {
        if (this._connecting) {
            return;
        }
        if (this._channel === null) {
            await this.connect();
        }

        this._channel.send({ name: bookmarkName, location });
    }

    async delete(bookmarkId) {
        if (this._connecting) {
            return;
        }
        if (this._channel === null) {
            await this.connect();
        }

        this._channel.send({ id: bookmarkId, delete: true });
    }
}

export default BookmarksSource;
