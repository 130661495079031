import React from 'react';
import TopBar from "./TopBar";
import {CssBaseline} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles({
    paper: {
        'padding-bottom': '30px',
        'padding-left': '35px',
        'padding-right': '35px',
        'padding-top': '20px',
    }
});

const SignInOne = ({authenticityToken}) => {
    const classes = useStyles();

    return (
        <>
            <CssBaseline/>
            <TopBar hideLogo/>
            <Container maxWidth="sm">
                <Typography variant="h5" component="h1">
                    SECURE LOGIN
                </Typography>
                <Paper className={classes.paper}>
                    <Typography variant="h6" component="h2">
                        S'IDENTIFIER
                    </Typography>
                    <form noValidate method="POST" action="/sign_in_1" accept-charset="UTF-8">
                        <input type="hidden" value={authenticityToken} name="authenticity_token"/>
                        <FormControl fullWidth>
                            <p>Entrez votre matricule ou votre adresse e-mail</p>
                            <TextField id="standard-basic"
                                       label="Matricule ou adresse e-mail" name="user[email_matricule]"/>
                        </FormControl>
                        <FormControl fullWidth>
                            <FormControlLabel
                                control={
                                    <Checkbox color="primary" name="user[remember_me]"/>
                                }
                                label="Se souvenir de moi"
                            />
                        </FormControl>
                        <Grid container justify="flex-end">
                            <Grid item sm={6}>
                                <FormControl fullWidth>
                                    <Button type="submit" variant="contained" color="primary">
                                        Suivant
                                    </Button>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Container>
        </>
    )
};

export default SignInOne;
