const mapReducer = (state, action) => {
    switch (action.type) {
        case 'open_add_bookmark_modal':
            return {  ...state, addBookmarkModalOpen: true };
        case 'close_add_bookmark_modal':
            return { ...state, addBookmarkModalOpen: false };
        case 'open_bookmarks_modal':
            return {  ...state, bookmarksModalOpen: true };
        case 'close_bookmarks_modal':
            return { ...state, bookmarksModalOpen: false };
        case 'add_bookmark':
            return { ...state, newBookmark: action.bookmark };
        case 'new_bookmark_sent':
            return { ...state, newBookmark: null };
        case 'bookmark_selected':
            return { ...state, selectedBookmark: action.bookmark };
        case 'bookmark_selection_sent':
            return { ...state, selectedBookmark: null };
        case 'bookmarks_updated':
            return { ...state, bookmarks: action.bookmarks };
        case 'delete_bookmark':
            return { ...state, bookmarkToDelete: action.bookmark };
        case 'bookmark_deleted':
            return { ...state, bookmarkToDelete: null };
        case 'send_location':
            return { ...state, location: action.location, newLocation: action.location };
        case 'location_sent':
            return { ...state, newLocation: null };
        case 'location_updated':
            return { ...state, location: action.location };
        case 'center_map_on_location':
            return { ...state, center: {...state.location, count: state.center.count++ } };
        default:
            return state;
    }
};

export default mapReducer;
