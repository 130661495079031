import React, {useEffect, useReducer, useRef} from 'react';
import Map from "./Map";
import {createConsumer} from "@rails/actioncable";
import CssBaseline from '@mui/material/CssBaseline';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import AddBookmarkModal from "./AddBookmarkModal";
import BookmarksSource from "./BookmarksSource";
import mapReducer from "./mapReducer";
import LocationSource from "./LocationSource";
import BookmarksModal from "./BookmarksModal";

const DispatchContext = React.createContext(null);

const FullMap = ({ apiKey, cableUrl, latitude, longitude }) => {
    const [state, dispatch] = useReducer(
        mapReducer,
        {
            bookmarks: [],
            newBookmark: null,
            addBookmarkModalOpen: false,
            bookmarksModalOpen: false,
            location: { latitude, longitude },
            center: { latitude, longitude, count: 0 },
        }
    );
    const bookmarksSourceRef = useRef(null);
    const locationSourceRef = useRef(null);

    useEffect(() => {
        const consumer = createConsumer(cableUrl);
        bookmarksSourceRef.current = new BookmarksSource(consumer);
        bookmarksSourceRef.current.onChange((bookmarks) => {
            dispatch({ type: 'bookmarks_updated', bookmarks });
        });
        bookmarksSourceRef.current.connect();

        locationSourceRef.current = new LocationSource(consumer);
        locationSourceRef.current.onChange((location) => {
            dispatch({ type: 'location_updated', location: JSON.parse(location) });
        });
        locationSourceRef.current.connect();

        return () => {
            consumer?.disconnect();
        }
    }, [cableUrl]);

    useEffect(() => {
        if (!state.newBookmark) {
            return;
        }
        bookmarksSourceRef.current?.create(state.newBookmark, {
            latitude: state.location.latitude,
            longitude: state.location.longitude,
            altitude: state.location.altitude,
            speed: 0.0,
            bearing: 0.0,
            save: true
        });
        dispatch({ type: 'new_bookmark_sent' });
    }, [bookmarksSourceRef.current, state.newBookmark]);

    useEffect(() => {
        if (!state.newLocation) {
            return;
        }
        locationSourceRef.current?.send(state.newLocation);
        dispatch({ type: 'location_sent' });
    }, [locationSourceRef.current, state.newLocation]);

    useEffect(() => {
        if (!state.selectedBookmark) {
            return;
        }
        dispatch({ type: 'send_location', location: state.selectedBookmark.location });
        dispatch({ type: 'bookmark_selection_sent' });
        dispatch({ type: 'center_map_on_location' });
    }, [state.selectedBookmark]);

    useEffect(() => {
        if (!state.bookmarkToDelete) {
            return;
        }
        bookmarksSourceRef.current?.delete(state.bookmarkToDelete.id);
        dispatch({ type: 'bookmark_deleted' });
    }, [state.bookmarkToDelete]);

    const openAddBookmarkModal = () => dispatch({ type: 'open_add_bookmark_modal' });
    const closeAddBookmarkModal = () => dispatch({ type: 'close_add_bookmark_modal' });

    const openBookmarksModal = () => dispatch({ type: 'open_bookmarks_modal' });
    const closeBookmarksModal = () => dispatch({ type: 'close_bookmarks_modal' });

    const handleCenterMap = () => dispatch({ type: 'center_map_on_location' });

    return (
        <DispatchContext.Provider value={dispatch}>
            <CssBaseline/>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            Map
                        </Typography>
                        <IconButton
                            size="large"
                            edge="end"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            onClick={openAddBookmarkModal}
                        >
                            <BookmarkAddIcon/>
                        </IconButton>
                        <IconButton
                            size="large"
                            edge="end"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            onClick={openBookmarksModal}
                            disabled={state.bookmarks.length === 0}
                        >
                            <BookmarksIcon/>
                        </IconButton>
                        <IconButton
                            size="large"
                            edge="end"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            onClick={handleCenterMap}
                            disabled={!state.location}
                        >
                            <GpsFixedIcon/>
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </Box>
            <Map
                apiKey={apiKey}
                initialLatitude={latitude}
                initialLongitude={longitude}
                latitude={state.location.latitude}
                longitude={state.location.longitude}
                center={state.center}
            />
            <AddBookmarkModal
                open={state.addBookmarkModalOpen}
                onClose={closeAddBookmarkModal}
                bookmarks={state.bookmarks}
            />
            <BookmarksModal
                open={state.bookmarksModalOpen}
                onClose={closeBookmarksModal}
                bookmarks={state.bookmarks}
            />
        </DispatchContext.Provider>
    );
}

export default FullMap;
export { DispatchContext };
