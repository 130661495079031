import { useEffect, useRef } from "react";
import dingDing from "../components/dingDing";

const sendNotification = (message) => {
    if (!('Notification' in window)) {
        return;
    }
    if (Notification.permission === 'granted') {
        new Notification(message);
    }
};

const useFlightEffect = (effect, deps) => {
    const updatesRef = useRef(0);
    updatesRef.current++;
    useEffect(() => {
        // initial load + undefined to the actual flight = 2 updates
        if (updatesRef.current > 2) {
            effect();
        }
    }, deps);
};

const useFlightNotifications = (flight) => {
    useEffect(() => {
        if (!('Notification' in window)) {
            return;
        }
        if (Notification?.permission !== 'granted') {
            Notification?.requestPermission();
        }
    }, []);

    useFlightEffect(() => {
        if (flight.state === 'in_progress') {
            dingDing.play();
            sendNotification(`${flight.callsign}, START UP APPROVED.`);
        }
    }, [flight?.state]);

    useFlightEffect(() => {
        sendNotification(`${flight.flightNumber}'s departure stand changed to ${flight.departureStand}`);
    }, [flight?.departureStand]);

    useFlightEffect(() => {
        sendNotification(`${flight.callsign} ARRIVAL STAND ${flight.arrivalStand}`);
    }, [flight?.arrivalStand]);

    useFlightEffect(() => {
        if (flight.state === 'planned') {
            sendNotification(`${flight.callsign} TOBT ${flight.tobt}`);
        }
    }, [flight?.tobt]);

    useFlightEffect(() => {
        sendNotification(`${flight.flightNumber}'s aircraft changed to ${flight.reg}`);
    }, [flight?.reg]);

    useFlightEffect(() => {
        sendNotification(`${flight.flightNumber} | ${flight.delayReasonShort}`);
    }, [flight?.delayReasonShort]);

    useFlightEffect(() => {
        switch(flight.boardingStatus) {
            case 'open':
                sendNotification(`${flight.flightNumber} | BOARDING`);
                break;
            case 'closed':
                sendNotification(`${flight.flightNumber} | CLOSED`);
                break;
            case 'not open':
                sendNotification(`${flight.flightNumber} | BOARDING EXPECTED`);
                break;
        }
    }, [flight?.boardingStatus]);
};

export default useFlightNotifications;
