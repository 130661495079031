import React, { useEffect, useState, useRef } from "react";
import { DateTime, Settings } from "luxon";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "./Link";
import TopBar from "./TopBar";
import FlightsSource from "./FlightsSource";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { createTheme, makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from '@material-ui/styles';
import { createConsumer } from "@rails/actioncable";
import useFlightNotifications from "../hooks/useFlightNotifications";
import createDispatchUrl from "./createDispatchUrl";

const useStyles = makeStyles({
    title: {
        'padding-top': '30px'
    },
    paper: {
        padding: '20px',
        'margin-bottom': '20px'
    },
});

const Flight = ({ flightID, cableUrl }) => {
    const [flight, setFlight] = useState();
    const classes = useStyles();
    const sourceRef = useRef(null);

    useEffect(() => {
        Settings.defaultZoneName = 'Europe/Paris';
    }, []);

    useEffect(() => {
        const consumer = createConsumer(cableUrl);

        sourceRef.current = new FlightsSource(consumer, flightID);
        sourceRef.current.connect();
        sourceRef.current.onChange((newFlight) => {
            setFlight(newFlight);
        });

        return () => {
            consumer?.disconnect();
            sourceRef.current = null;
        }
    }, [flightID, cableUrl, sourceRef])

	useEffect(() => {
		if (flight) {
			document.title = flight.flightNumber;
		}
	}, [flight?.flightNumber]);

    useFlightNotifications(flight);

    if (!flight) {
        return <div>Chargement en cours...</div>
    }

    const handleForceUpdate = () => {
        sourceRef.current.refresh();
    };

    const handleShowStatus = () => {
        window.open(flight.statusLink, '_blank');
    }

    const handleRadar = () => {
        window.open(flight.radarLink, '_blank');
    }

    const handleFlightDispatch = () => {
        window.open(createDispatchUrl(flight), '_blank');
    }

    const handleShowOfp = () => {
        // open current url appended with /ofp in a new tab
        window.open(`${window.location.href}/ofp`, '_blank');
    }

    const darkTheme = createTheme({
        palette: {
            type: 'dark',
        }
    });

    // from moment(flight.departureDatetime).format('L')
    const formattedDepartureDatetime = DateTime.fromISO(flight.departureDatetime).toLocaleString(DateTime.DATE_SHORT);
    // moment(flight.updatedAt).format('LTS')
    const formattedUpdatedAt = DateTime.fromISO(flight.updatedAt).toLocaleString(DateTime.TIME_WITH_SECONDS);
    // moment(flight.departureDatetime).format('LT')
    const formattedDepartureTime = DateTime.fromISO(flight.departureDatetime).toLocaleString(DateTime.TIME_SIMPLE);
    // moment(flight.publishedDepartureDatetime).format('LT')
    const formattedPublishedDepartureTime = DateTime.fromISO(flight.publishedDepartureDatetime).toLocaleString(DateTime.TIME_SIMPLE);
    // moment(flight.arrivalDatetime).format('LT')
    const formattedArrivalTime = DateTime.fromISO(flight.arrivalDatetime).toLocaleString(DateTime.TIME_SIMPLE);
    // moment(flight.expectedArrivalDatetime).format('LT')
    const formattedExpectedArrivalTime = DateTime.fromISO(flight.expectedArrivalDatetime).toLocaleString(DateTime.TIME_SIMPLE);

    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline/>
            <TopBar
                flightID={flightID}
                onRefresh={handleForceUpdate}
                onShowStatus={handleShowStatus}
                onRadar={handleRadar}
                onFlightDispatch={handleFlightDispatch}
                onShowOfp={handleShowOfp}
            />
            <Container maxWidth="md">
                <Typography variant="h4" gutterBottom className={classes.title}>
                    {`Détails du vol ${flight.flightNumber} du ${formattedDepartureDatetime}`}
                </Typography>
                <Typography variant="caption" gutterBottom>
                    <i>Dernière mise à jour: {formattedUpdatedAt}</i>
                </Typography>
                <Paper className={classes.paper}>
                    <Typography variant="h6">Informations opérationnelles</Typography>

                    <b>
                        <p>
                            <Link href={`https://www.flightradar24.com/data/flights/${flight.flightNumber}`}>{flight.flightNumber}{flight.callsign && ` / ${flight.callsign}`}</Link></p>
                        <p>{flight.aircraftIcao} <Link href={`https://www.flightradar24.com/data/aircraft/${flight.reg}`}>{flight.reg}</Link> {flight.wifiEnabled ? '(wifi)' : '(no wifi)'}</p>
                        {flight.departureStand && <p>STAND <Link href={`/stands/${flight.departureIata}/${flight.departureStand}`}>{flight.departureStand}</Link></p>}
                        {flight.sobt && <p>SOBT {flight.sobt}</p>}
                        {flight.tobt && <p>TOBT {flight.tobt}</p>}
                        {flight.tsat && <p>TSAT {flight.tsat}</p>}
						{flight.delayReasonLong && <p>{flight.delayReasonLong}</p>}
						{flight.boardingStatus === 'not open' && <p>Embarquement en attente</p>}
						{flight.boardingStatus === 'open' && <p>Embarquement en cours</p>}
						{flight.boardingStatus === 'closed' && <p>Embarquement clos</p>}
                        {flight.aobt && <p>AOBT {flight.aobt}</p>}
                        {flight.sibt && <p>SIBT {flight.sibt}</p>}
                        {flight.aibt && <p>AIBT {flight.aibt}</p>}
                        {flight.arrivalStand && <p>STAND <Link href={`/stands/${flight.arrivalIata}/${flight.arrivalStand}`}>{flight.arrivalStand}</Link></p>}
                    </b>
                </Paper>

                <Paper className={classes.paper}>
                    <Typography variant="h6">Détails du vol</Typography>
                    <Typography variant="caption">Tous les horaires sont affichés en heure française.</Typography>

                    <p>
                        Statut :
                        <b>
                            {flight.state === 'planned' && ' prévu'}
                            {flight.state === 'in_progress' && ' en cours'}
                            {flight.state === 'finished' && ' terminé'}
                        </b>
                    </p>
                    <p>Route : <b>{flight.departureIata} -> {flight.arrivalIata}</b></p>
                    <p>Appareil : <b>{flight.aircraftIcao}</b></p>
                    <p>Durée de vol prévue : <b>{flight.durationInWords}</b></p>
                    <p>Durée de
                        vol {flight.state === 'finished' ? 'effectuée' : 'estimée'} : <b>{flight.estimatedDurationInWords}</b></p>

                    <Typography variant="h6">Départ</Typography>

                    <p>Départ prévu à <b>{formattedDepartureTime}</b></p>
                    <p>Départ {flight.state === 'planned' ? 'estimé' : 'effetué'} à <b>{formattedPublishedDepartureTime}</b></p>

                    <Typography variant="h6">Arrivée</Typography>

                    <p>Arrivée prévue à <b>{formattedArrivalTime}</b></p>
                    <p>Arrivée {flight.state === 'finished' ? 'effectuée' : 'estimée'} à <b>{formattedExpectedArrivalTime}</b></p>
                </Paper>
            </Container>
        </ThemeProvider>
    );
};

export default Flight;

