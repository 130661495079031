import React, {useState, useEffect} from "react";
import axios from "axios";
import ActivityItem from './ActivityItem';
import List from '@material-ui/core/List';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    root: {
        padding: 0
    }
});

export default function ActivitiesList() {
    const [activities, setActivities] = useState();

    const classes = useStyles();

    useEffect(() => {
        axios
            .get(
                "/api/activities"
            )
            .then(({data}) => {
                setActivities(data);
            });
    }, []);

    return activities ? (
        <List className={classes.root}>
            {activities.map(dayActivities => <ActivityItem key={`activities-${dayActivities[0]}`} day={dayActivities[0]} activities={dayActivities[1]}/>)}
        </List>
    ) : (
        <div>Chargement en cours...</div>
    )
}
