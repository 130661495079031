import React, {useCallback, useContext, useEffect, useRef} from 'react';
import GoogleMapReact from 'google-map-react';
import { DispatchContext} from './FullMap';

import './Map.css'

const GoogleMapReactMemo = React.memo(GoogleMapReact, (prevProps, nextProps) => true);

const Map = ({apiKey, initialLatitude, initialLongitude, latitude, longitude, center}) => {
    const defaultProps = {
        center: {lat: initialLatitude, lng: initialLongitude},
        zoom: 11
    };
    const mapRef = useRef(null);
    const markerRef = useRef(null);
    const elevatorRef = useRef(null);
    const dispatch = useContext(DispatchContext);

    useEffect(() => {
        markerRef.current?.setPosition({lat: latitude, lng: longitude});
    }, [latitude, longitude]);

    useEffect(() => {
        mapRef.current?.setCenter({lat: center.latitude, lng: center.longitude});
    }, [center]);

    const sendLocation = useCallback((location) => {
        dispatch({
            type: 'send_location',
            location: {
                altitude: 0.0,
                speed: 0.0,
                bearing: 0.0,
                save: true,
                ...location,
            }
        });
    }, [dispatch])

    const handleMarkerMoved = useCallback((location) => {
        elevatorRef.current.getElevationForLocations({
            locations: [location]
        }).then(({results}) => {
            let altitude = 0.0;
            if (results[0]) {
                altitude = results[0].elevation;
            }

            sendLocation({latitude: location.lat, longitude: location.lng, altitude});
        }).catch(() => {
            sendLocation({latitude: location.lat, longitude: location.lng});
        });
    }, [elevatorRef, sendLocation]);

    const mapLoaded = useCallback(({map, maps}) => {
        mapRef.current = map;

        const icon = {
            path: maps.SymbolPath.CIRCLE,
            fillColor: "rgba(66,133,244,255)",
            fillOpacity: 1.0,
            strokeWeight: 2,
            strokeColor: "white",
            scale: 7
        };

        map.setTilt(0);

        markerRef.current = new maps.Marker({
            icon,
            position: {lat: initialLatitude, lng: initialLongitude},
            map,
            draggable: true
        });

        markerRef.current.addListener('dragend', () => {
            const position = markerRef.current.getPosition();
            handleMarkerMoved({lat: position.lat(), lng: position.lng()});
        });

        elevatorRef.current = new maps.ElevationService();
    }, [initialLatitude, initialLongitude, handleMarkerMoved]);

    return (
        <div className="map">
            <GoogleMapReactMemo
                options={{
                    mapTypeId: "hybrid",
                    mapTypeControl: true,
                    mapTypeControlOptions: {
                        style: 2,
                        mapTypeIds: ["roadmap", "terrain", "hybrid", "satellite"]
                    }
                }}
                bootstrapURLKeys={{key: apiKey}}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={mapLoaded}
            />
        </div>
    );
}

export default Map;
