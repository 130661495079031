import React from 'react';
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar/AppBar";
import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from '@material-ui/icons/Refresh';
import AirlinesIcon from '@mui/icons-material/Airlines';
import RouteIcon from '@mui/icons-material/Route';
import SettingsIcon from '@material-ui/icons/Settings';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    logo: {
        width: '200px',
        '& img': {
            width: '100%'
        }
    },
    topBar: {
        background: 'linear-gradient(to right, #0800b9, #00a1de)'
    }
});

export default function TopBar({
                                   user,
                                   hideLogo,
                                   flightID,
                                   onRefresh,
                                   onShowStatus,
                                   onRadar,
                                   onFlightDispatch,
                                   onShowOfp,
}) {
    const classes = useStyles();

    const handleFlightRefresh = () => {
        if (onRefresh) {
            onRefresh();
        }
    }

    const handleFlightStatus = () => {
        if (onShowStatus) {
            onShowStatus();
        }
    }

    const handleRadar = () => {
        if (onRadar) {
            onRadar();
        }
    }

    const handleFlightDispatch = () => {
        if (onFlightDispatch) {
            onFlightDispatch();
        }
    }

    const handleShowOfp = () => {
        if (onShowOfp) {
            onShowOfp();
        }
    }

    return (
        <AppBar position="static" className={classes.topBar}>
            <Toolbar>
                {
                    !hideLogo && (
                        <div className={classes.logo}>
                            <img src="https://fedidp.airfranceklm.com/assets/nux/img/logo-afkl-white.png"
                                 alt="Air France KLM logo"/>
                        </div>
                    )
                }
                {
                    flightID && (
                        <>
                            <IconButton
                                onClick={handleFlightRefresh}
                                color="inherit"
                            >
                                <RefreshIcon />
                            </IconButton>
                            <IconButton
                                onClick={handleFlightStatus}
                                color="inherit"
                            >
                                <AirlinesIcon />
                            </IconButton>
                            <IconButton
                                onClick={handleRadar}
                                color="inherit"
                            >
                                <RouteIcon />
                            </IconButton>
                            <IconButton
                                onClick={handleFlightDispatch}
                                color="inherit"
                            >
                                <SettingsIcon />
                            </IconButton>
                            <IconButton
                                onClick={handleShowOfp}
                                color="inherit"
                            >
                                <AttachFileIcon />
                            </IconButton>
                        </>
                    )
                }
            </Toolbar>
        </AppBar>
    )
}
