import React, {useCallback, useContext, useEffect, useState} from "react";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';

import {DispatchContext} from "./FullMap";

const BookmarksModal = ({open, onClose, bookmarks}) => {
    const dispatch = useContext(DispatchContext);

    const [displayedBookmarks, setDisplayedBookmarks] = useState(bookmarks);

    useEffect(() => {
        setDisplayedBookmarks(bookmarks);
    }, [bookmarks]);

    const handleFilterChange = useCallback((event) => {
        const search = event.target.value.toLowerCase();
        if (search.length === 0) {
            setDisplayedBookmarks(bookmarks);
        } else {
            setDisplayedBookmarks(bookmarks.filter(bookmark => bookmark.name.toLowerCase().includes(search)));
        }
    }, [bookmarks]);

    const handleDelete = useCallback(bookmark => {
        confirm("Are you sure you want to delete this bookmark?") &&
        dispatch({type: "delete_bookmark", bookmark});
    }, [dispatch]);

    const handleClose = useCallback(() => {
        setDisplayedBookmarks(bookmarks);
        onClose();
    }, [onClose]);

    return <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Bookmarks</DialogTitle>
        <DialogContent>
            {
                bookmarks.length === 0 ? (
                    <p>No bookmarks</p>
                ) : (
                    <>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Search"
                            type="text"
                            fullWidth
                            onChange={handleFilterChange}
                            autoComplete="off"
                            size="small"
                        >
                        </TextField>
                        {displayedBookmarks.length === 0 && <p>No bookmarks found.</p>}
                        <List sx={{pt: 0}} dense="true">
                            {
                                displayedBookmarks.map(bookmark => (
                                    <ListItem
                                        key={bookmark.id}
                                        onClick={() => {
                                            dispatch({
                                                type: 'bookmark_selected',
                                                bookmark
                                            });
                                            handleClose();
                                        }}
                                    >
                                        <ListItemButton>
                                            <ListItemText primary={bookmark.name}/>
                                        </ListItemButton>
                                        <ListItemSecondaryAction>
                                            <IconButton edge="end"
                                                        onClick={() => handleDelete(bookmark)}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))
                            }
                        </List>
                    </>
                )
            }
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose}>Close</Button>
        </DialogActions>
    </Dialog>
};

export default BookmarksModal;
