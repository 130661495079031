class LocationSource {
    constructor(consumer) {
        this._consumer = consumer;
        this._location = [];
        this._channel = null;
        this._connecting = false;
    }

    connect() {
        if (!this._consumer) {
            return;
        }

        this._connecting = true;
        return new Promise(resolve => {
                this._channel = this._consumer.subscriptions.create({channel: "LocationsChannel"}, {
                    received: (data) => {
                        this._location = data;
                        if (this._onChange) {
                            this._onChange(this._location);
                        }
                    },
                    connected: () => {
                        this._connecting = false;
                        resolve();
                    }
                });
            }
        )
    }

    disconnect() {
        if (this._connecting) {
            return;
        }

        this._channel?.unsubscribe();
        this._channel = null;
    }

    onChange(callback) {
        this._onChange = callback;
    }

    async getLocation() {
        if (this._connecting) {
            return [];
        }

        return this._location;
    }

    async send(location) {
        if (this._connecting) {
            return;
        }
        if (this._channel === null) {
            await this.connect();
        }

        this._channel.send(location);
    }
}

export default LocationSource;
