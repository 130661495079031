// Entry point for the build script in your package.json
// import "@hotwired/turbo-rails";
import { define } from 'remount';

// import "./controllers";
import {
    SignInOne,
    SignInTwo,
    Home,
    Flight,
    FullMap,
} from "./components";

define({
    'x-sign-in-1': SignInOne,
    'x-sign-in-2': SignInTwo,
    'x-home': Home,
    'x-flight': Flight,
    'x-full-map': FullMap,
});
